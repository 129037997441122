import { defineStore } from 'pinia'
import type { Mission, UserAlert, UserAlertMission } from '#imports'

export const useUserAlertStore = defineStore('userAlert', {
  state: () => ({
    isFetching: false,
    display: null as string | null,
    alerts: [] as UserAlert[],

    showOverlay: false,
    selectedAlert: null as UserAlert | null,
    unreadMissions: [] as Mission[],
    selectedMission: null as Mission | null,
  }),
  getters: {
    activeAlerts: (state) => {
      return state.alerts.filter((alert) => alert.is_active)
    },
    showFormOverlay: (state) => {
      return state.display === 'form' && state.showOverlay === true
    },
    showMissionsOverlay: (state) => {
      return (
        state.display === 'missions' && state.showOverlay === true && state.selectedAlert !== null
      )
    },
    currentMission: (state) => {
      return state.unreadMissions.length > 0 ? state.unreadMissions[0] : null
    },
    unreadMissionsCount: (state) => {
      return state.unreadMissions.length
    },
  },
  actions: {
    async fetch() {
      this.isFetching = true
      const { data, error } = await useApiFetch<QueryBuilderResponse>('/user/alerts')
      if (error.value) {
        return error.value
      }
      if (data.value) {
        this.alerts = data.value.data as UserAlert[]
      }
      this.isFetching = false
    },
    async fetchUnreadMissions(userAlertId: number) {
      this.isFetching = true
      const { data, error } = await useApiFetch<QueryBuilderResponse>(
        `/user/alerts/${userAlertId}/unread-missions`
      )
      if (error.value) {
        return error.value
      }
      if (data.value) {
        this.unreadMissions = data.value.data as Mission[]
      }
      this.isFetching = false
    },
    showDisplayOverlay(alert: UserAlert | null, display: string) {
      this.display = display
      this.showOverlay = true
      this.selectedAlert = alert
    },
    async deleteUserAlertMission(userAlertId: number, userAlertMissionId: number) {
      const { data, error } = await useApiFetch<QueryBuilderResponse>(
        `/user/alerts/${userAlertId}/missions/${userAlertMissionId}`,
        {
          method: 'DELETE',
        }
      )
    },
    async addMissionToFavorite(userAlertId: number, userAlertMissionId: number) {
      const { data, error } = await useApiFetch<QueryBuilderResponse>(
        `/user/alerts/${userAlertId}/missions/${userAlertMissionId}/add-to-favorite`,
        {
          method: 'POST',
        }
      )
    },
    nextMission() {
      this.unreadMissions.shift()
    },
    resetOverlay() {
      this.display = null
      this.selectedAlert = null
      this.unreadMissions = []
    },
    async closeOverlay() {
      this.showOverlay = false
      // Wait for fade
      await new Promise((resolve) => setTimeout(resolve, 500))
      this.resetOverlay()
    },
  },
})
