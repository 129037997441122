import { default as accessibiliteHagGTEmsfIMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/accessibilite.vue?macro=true";
import { default as _91slug_93V5KLpNWPUFMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/activites/[slug].vue?macro=true";
import { default as editVY7E9UsaLvMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexaTm1YMVgLHMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addH5EiyVnGBxMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/add.vue?macro=true";
import { default as index4IKLUnW6GdMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/index.vue?macro=true";
import { default as edit4qlItJdrQqMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addQ8opCjp8dLMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexvDQ7KvDW1bMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as edit1iGmdAVKsMMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addvQCyhEE5c1Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexgwx6J0HVUKMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as editjLzBK4YCxfMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexOrRBmJGGuRMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addVEMXZoUbInMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexnkFoONPEOcMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editjudj4GC8qxMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addOZvEmJ2REfMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexXisstGKGMIMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as edit7LgENQ7tNGMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexLP9VkVkyjVMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addCktUp8rHAEMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexzSmcHHSkE9Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as edit266OF8OS82Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexoZnorTcUmkMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexSOFHg2TgHgMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/index.vue?macro=true";
import { default as benevoles_45informations9sxjoOoxHBMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevoleszwXaiUFWKbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as dateser3qG7NuhLMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexZvu0qA7rZIMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informations1Gis1NXL08Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxk98q3bEweTMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsables8tHpSCxyx8Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titleDpRYe4CjXhMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevolesq5DuhrPy5zMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visuel5RyoSVCAjHMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexn2roj1mRurMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/index.vue?macro=true";
import { default as notesZiP3nifT5JMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/notes.vue?macro=true";
import { default as editr81nSHvHDBMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexOrpbUeIM9mMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addulkcF1c914Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexVlequlZlfaMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsA2W6gYLP3tMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/other/invitations.vue?macro=true";
import { default as logstgRIB6AO7PMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/other/logs.vue?macro=true";
import { default as indexhSHwopO3o1Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesHdQ20LDiq7Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/ressources.vue?macro=true";
import { default as emailswAysv1Q0b1Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/emails.vue?macro=true";
import { default as generalGfNuYqYkw0Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/general.vue?macro=true";
import { default as messagesPMDE3srsZlMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/messages.vue?macro=true";
import { default as editgYWjx7ptTqMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsS7dlcOx5hDMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addJLal43CsNKMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/add.vue?macro=true";
import { default as index3WGLKTZG7mMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantKZSYl868JbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortant6ntMAI6pHJMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversions0YlNms6pfXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validerRirySSqdsbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeeszid2zAacPdMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerqTVBqRNeu5Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexxWFI1Wpp8dMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesamxdOkH2XXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsacdiaD9qftMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/missions.vue?macro=true";
import { default as index4eoxCLSt0BMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeeseoPFCndyI0Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerg95TVMRTVPMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesmT82xMMxPbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participations1FYOSU0nwOMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/participations.vue?macro=true";
import { default as placesasOJhMCXRRMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsEWYrdtCergMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursVc6kTQHVnnMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as editbA9R9tMgpWMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addjwIUsncOkzMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexyGVLCZ5UbdMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesCnDDVBEAgBMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/temoignages.vue?macro=true";
import { default as edit19MQvboEetMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexq4RPYSYVIYMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexcF0NlVcBnCMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolescpCfocTlhSMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexY6wUOXTSF4Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesQKLboy2FsQMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiqueBkx1oyaN84Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationsdF9DpiTq8Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexSRezWTCp8BMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93B5NV4Pcd8DMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/departements/[slug].vue?macro=true";
import { default as _91slug_932EjilqJZP5Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45moment2Wnb0MCqyKMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/en-ce-moment.vue?macro=true";
import { default as indexSiJYSv8OzqMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/index.vue?macro=true";
import { default as indexF8EbBgamN9Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/index.vue?macro=true";
import { default as competencespwmX1CHniQMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesXzxOpcspipMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesrR6XUz45z9Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profileufrDVocCeEMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexKXhomzQUckMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/index.vue?macro=true";
import { default as indexRKjRUnN0ZmMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementnQUkgo6SHZMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmation6pdO2hnquwMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectiviteffiYgUKsACMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmation5UlckO1C04Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsWx4luyM4qbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesloMJr4w0QXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationWeZdwrCgCbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profileLSQ92GaS9KMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93EN4dy12atyMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/invitations/[hash].vue?macro=true";
import { default as loginMY7tWALT1SMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/login.vue?macro=true";
import { default as adminvM79lg0HnbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/maintenance/admin.vue?macro=true";
import { default as indexiJmlx1j2DZMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesGEDKF197CXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/mentions-legales.vue?macro=true";
import { default as _91id_93kz9awwQ2RXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/[id].vue?macro=true";
import { default as index4ZqB3ZBAOJMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/index.vue?macro=true";
import { default as add8NsJ6WzcwdMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/modeles/add.vue?macro=true";
import { default as indexrGY3AhCMbEMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93mWllVG45M5Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexEvv7dYwVZrMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexJZ7yi604FEMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/index.vue?macro=true";
import { default as notifications0U4b8OpheqMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/notifications.vue?macro=true";
import { default as _91slug_93LWcOxVF83cMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/organisations/[slug].vue?macro=true";
import { default as indexU9XKKyM4bpMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/organisations/index.vue?macro=true";
import { default as _91token_93LqISb3Rm1cMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/password-reset/[token].vue?macro=true";
import { default as indexKSBMTlbgGTMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteeVDlPKI37aMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialitejCj5wDrUPpMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexNBQUgASjznMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/alertes/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementGCNMSNUCcbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editbKfTLkylcgMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/edit.vue?macro=true";
import { default as favorisL80KThq5koMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/favoris.vue?macro=true";
import { default as indexT67NABOvfgMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/index.vue?macro=true";
import { default as missionsJ7lJvFmWkxMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/missions.vue?macro=true";
import { default as notificationssOXSgSiOJrMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/notifications.vue?macro=true";
import { default as preferences1VNSG18bXNMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/preferences.vue?macro=true";
import { default as settingsAQPXEoFCTeMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensemblenY5FChx6wlMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiquehfLPt27mo9Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/generique.vue?macro=true";
import { default as mgenTYuvJR5vJdMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planetelGD7N9HFw7Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendrekg6RVIzbm5Meta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93GqqtIexrjwMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/reseaux/[slug].vue?macro=true";
import { default as indexSPcObnSSpWMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/index.vue?macro=true";
import { default as missionsKvE5CUzM3lMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/missions.vue?macro=true";
import { default as organisationsaEc8bpodFbMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/organisations.vue?macro=true";
import { default as participationsTePttCcIyjMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/participations.vue?macro=true";
import { default as places4heZVmYeSHMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/places.vue?macro=true";
import { default as utilisateurs3swXhJoHLsMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersQdkmJOFmXMMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationqE7ySUcMccMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45rolermFkrtom6IMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45link09vN5xeL6zMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsjVosGtAzGCMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresW1arVRX4caMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as index4AZGP4wsUuMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/index.vue?macro=true";
import { default as activity_45logsrbF7T6DBvHMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actions3jU7pb8xQXMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdated3UHV9R06KPMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedmMwNU5wDotMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93LAuVR2TNlZMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/temoignages/[token].vue?macro=true";
import { default as indexf2JCG4maacMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/territoires/index.vue?macro=true";
import { default as _91slug_93rmNxLpD4ysMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/villes/[slug].vue?macro=true";
import { default as component_45stubj1P7WnyQSMMeta } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubj1P7WnyQSM } from "/build/16590ef5-1350-4293-9fb8-77de74acfd87/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editVY7E9UsaLvMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexaTm1YMVgLHMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addH5EiyVnGBxMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: index4IKLUnW6GdMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: edit4qlItJdrQqMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addQ8opCjp8dLMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexvDQ7KvDW1bMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: edit1iGmdAVKsMMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addvQCyhEE5c1Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexgwx6J0HVUKMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editjLzBK4YCxfMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexOrRBmJGGuRMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addVEMXZoUbInMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexnkFoONPEOcMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editjudj4GC8qxMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addOZvEmJ2REfMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexXisstGKGMIMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: edit7LgENQ7tNGMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexLP9VkVkyjVMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addCktUp8rHAEMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexzSmcHHSkE9Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: edit266OF8OS82Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexoZnorTcUmkMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexSOFHg2TgHgMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informations9sxjoOoxHBMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevoleszwXaiUFWKbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: dateser3qG7NuhLMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexZvu0qA7rZIMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informations1Gis1NXL08Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxk98q3bEweTMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsables8tHpSCxyx8Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titleDpRYe4CjXhMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevolesq5DuhrPy5zMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuel5RyoSVCAjHMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexn2roj1mRurMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesZiP3nifT5JMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editr81nSHvHDBMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexOrpbUeIM9mMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addulkcF1c914Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexVlequlZlfaMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsA2W6gYLP3tMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logstgRIB6AO7PMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexhSHwopO3o1Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailswAysv1Q0b1Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-general",
    path: "/admin/settings/general",
    meta: generalGfNuYqYkw0Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesPMDE3srsZlMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editgYWjx7ptTqMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsS7dlcOx5hDMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addJLal43CsNKMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: index3WGLKTZG7mMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantKZSYl868JbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortant6ntMAI6pHJMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversions0YlNms6pfXMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validerRirySSqdsbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeeszid2zAacPdMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerqTVBqRNeu5Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexxWFI1Wpp8dMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesamxdOkH2XXMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsacdiaD9qftMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: index4eoxCLSt0BMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeeseoPFCndyI0Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerg95TVMRTVPMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesmT82xMMxPbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participations1FYOSU0nwOMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesasOJhMCXRRMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsEWYrdtCergMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursVc6kTQHVnnMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: editbA9R9tMgpWMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addjwIUsncOkzMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexyGVLCZ5UbdMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: edit19MQvboEetMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexq4RPYSYVIYMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexcF0NlVcBnCMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexSRezWTCp8BMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexF8EbBgamN9Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencespwmX1CHniQMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitesXzxOpcspipMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesrR6XUz45z9Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profileufrDVocCeEMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexKXhomzQUckMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmation6pdO2hnquwMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectiviteffiYgUKsACMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmation5UlckO1C04Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsWx4luyM4qbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesloMJr4w0QXMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationWeZdwrCgCbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profileLSQ92GaS9KMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93EN4dy12atyMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginMY7tWALT1SMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: adminvM79lg0HnbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexiJmlx1j2DZMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93kz9awwQ2RXMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: index4ZqB3ZBAOJMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: add8NsJ6WzcwdMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexrGY3AhCMbEMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexJZ7yi604FEMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications0U4b8OpheqMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93LWcOxVF83cMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93LqISb3Rm1cMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexKSBMTlbgGTMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-alertes",
    path: "/profile/alertes",
    meta: indexNBQUgASjznMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/alertes/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editbKfTLkylcgMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-favoris",
    path: "/profile/favoris",
    meta: favorisL80KThq5koMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/favoris.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexT67NABOvfgMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsJ7lJvFmWkxMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationssOXSgSiOJrMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferences1VNSG18bXNMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsAQPXEoFCTeMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensemblenY5FChx6wlMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiquehfLPt27mo9Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgenTYuvJR5vJdMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planetelGD7N9HFw7Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendrekg6RVIzbm5Meta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93GqqtIexrjwMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexSPcObnSSpWMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionsKvE5CUzM3lMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationsaEc8bpodFbMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationsTePttCcIyjMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: places4heZVmYeSHMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateurs3swXhJoHLsMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersQdkmJOFmXMMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationqE7ySUcMccMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45rolermFkrtom6IMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45link09vN5xeL6zMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationsjVosGtAzGCMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresW1arVRX4caMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: index4AZGP4wsUuMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logsrbF7T6DBvHMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actions3jU7pb8xQXMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdated3UHV9R06KPMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedmMwNU5wDotMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93LAuVR2TNlZMeta || {},
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/16590ef5-1350-4293-9fb8-77de74acfd87/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/user/settings",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/inscription/organisation",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/statistiques",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/missions-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/organisations-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/villes-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/departements-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/reseaux-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  },
  {
    name: component_45stubj1P7WnyQSMMeta?.name,
    path: "/activites-sitemap.xml",
    component: component_45stubj1P7WnyQSM
  }
]